import { Container } from '@finn/atoms';
import { Brand } from '@finn/ua-vehicle';
import { Slider } from '@finn/ui-modules';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { BrandSliderItem } from '~/types/slider';
import BrandItem from '~/ui-elements/BrandItem';
import { convertBrandsToSliderItems } from '~/utils/brands';
import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(5),
  },
}));

interface IProps {
  title?: string;
  brands: Brand[];
}

const BrandSlider: React.FunctionComponent<IProps> = ({ title, brands }) => {
  const classes = useStyles();

  const items: BrandSliderItem[] = convertBrandsToSliderItems(brands);

  return (
    <div data-testid="brandslider">
      {title && (
        <Container>
          <Typography className={classes.title} variant="h2">
            {parseToHtml(title)}
          </Typography>
        </Container>
      )}
      <Slider>
        {items.map((item) => (
          <BrandItem key={item.key} item={item} />
        ))}
      </Slider>
    </div>
  );
};

export default BrandSlider;
